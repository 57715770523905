import React from 'react'
import Title from '../../GeneralComponents/Title/Title'
import './dates.css'
import {OrderJSONArrayAsc} from '../../OrderJSONArray'

import ProcessAPIData from './ProcessAPIData'


export default function Dates(props) {

    const events = props.events;
    
    return(
        <div className="dates">
            <Title text="Dates" />
            <div className="container">    
                {props.events && <ProcessAPIData events={events} openPopup={props.openPopup} />}
                {/*<div className="primary-cta">Alle anzeigen</div>*/}
            </div>
        </div>
        
    )
}