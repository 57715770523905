import React from 'react'
import './herotitle.css'
import Title from '../Title/Title'

export default function HeroTitle(props) {
    return (
        <div className="HeroTitle">
            <Title text={props.text} />
        </div>
    )
}