import React from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../../src/photos/Logo.png';
import './footer.css';

export default function Footer() {
    return(
        <div className="FooterBG">
            <div className="container">
                <div className="Footer">
                    <NavLink activeClassName="active" exact to="/" ><img src={Logo} alt="Logo" /></NavLink>
                    <div className="legal">
                        <p className="FooterHeader">Rechtliches</p>
                        <NavLink activeClassName="active" to="/Impressum" className="FooterLink">Impressum</NavLink>
                        <NavLink activeClassName="active" to="/Datenschutz" className="FooterLink">Datenschutz</NavLink>
                    </div>
                    <div className="navigation">
                        <p className="FooterHeader">Navigation</p>
                        <NavLink activeClassName="active" to="/" className="FooterLink">Home</NavLink>
                        <NavLink activeClassName="active" to="/Info" className="FooterLink">Info</NavLink>
                        {/*<NavLink activeClassName="active" to="/Galerie" className="FooterLink">Galerie</NavLink>
                       
    <NavLink activeClassName="active" to="/Kontakt" className="FooterLink">Kontakt</NavLink>*/}
                    </div>
                </div>
            </div>
            <div className="copy-right">Made with ❤️ by <a href="https://wa.me/491755556578" target="_blank">Marius Gebert</a></div>
        </div>
    )
}