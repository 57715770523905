import React from 'react';
import { NavLink } from 'react-router-dom';
import Title from '../../GeneralComponents/Title/Title'
import './gallery.css'

import Img1 from '../../src/photos/img1.png';
import Img2 from '../../src/photos/img2.png';
import Img3 from '../../src/photos/img3.png';

export default function Gallery() {
    return(
        <div className="Gallery">
            <Title text="Fotos"></Title>
            <div className="container">
                {/*<p className="GalleryInformation">Wenn du hier noch Text haben willst um was zu den Fotos zu sagen würde der hier stehen.</p>*/}
                <div className="ImagePreview">
                    <div className="Image" style={{ backgroundImage : 'url('+ Img1 +')' }} />
                    <div className="Image" style={{ backgroundImage : 'url('+ Img2 +')' }} />
                    <div className="Image" style={{ backgroundImage : 'url('+ Img3 +')' }} />
                </div>
                    {/*<div className="primary-cta"><NavLink to="/Galerie">Alle ansehen</NavLink></div>*/}
            </div>
        </div>
    )
}