import Img1 from '../../src/photos/img1.png'
import Img2 from '../../src/photos/img2.png'
import Img3 from '../../src/photos/img3.png'

export default {
    Data: [ 
    {
        headline: "Eingang",
        headlineimagecount: 5
    },
    {
        headline: "Bar",
        headlineimagecount: 3
    },
    {
        headline: "Floor 1",
        headlineimagecount: 2
    },
    ],
    Images : [Img1,Img2,Img3,Img1,Img2,Img3,Img1,Img2,Img3,Img2]

}