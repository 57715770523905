import React from 'react'

export default function Impressum() {
    return(
        <div className="Galerie">
            <div className="container container-after-header">
                <h2>Impressum</h2>
                <h3>Firmenanschrift</h3>
                <p>
                    D-G-E GmbH<br />
                    Friedrichstrasse 28a<br />
                    01067 Dresden<br /><br />

                    Tel: 0351/8020066<br />
                    Fax: 0351/8020067<br /><br />

                    e-mail: office@d-g-e.de<br /><br />

                    USt-IdNr.: DE224828693<br />
                    Handelsregister Dresden HRB 21165<br />
                    vertreten durch Geschäftsführer<br /><br />

                    Copyright: alle Inhalte, insbesondere Fotomaterial sind kopierrechtlich geschützt
               </p>
        
                <h3>Verantwortlich für Inhalt der Website</h3>
               <p>
                    Marius Gebert<br />
                    Ratsfeld 17<br />
                    01187 Dresden<br />
                    Deutschland<br /><br />

                    gebert.marius@gmail.com<br />
               </p>
               <h3>Verantwortlich für Datenverarbeitung</h3>
               <p>
                    D-G-E GmbH<br />
                    Friedrichstraße 28a<br />
                    01069 Dresden<br />
                    Deutschland<br /><br />

                    office@d-g-e.de<br /><br />

                    Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
               </p>
            </div>
        </div>
    )
}