import React from 'react'

export default function ProcessAPIData(props) {
    
    var data = props.events;
    const list = [];
    const titellength = window.innerWidth > 600 ? 35 : 22;
    
    var i;
    for (i= 0; i<data.length; i++) {
        data[i].shortname = data[i].name.substring(0, titellength)+'...';
        
        var days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
        var months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        
        var date = new Date(data[i].start_time.substring(0, 10));
        //var date = new Date(Date.now());
        
        data[i].month = months[date.getMonth()];
        data[i].weekday = days[date.getDay()];
        data[i].daynumber = date.getDate();
        data[i].year = date.getFullYear()    
    }
    
    var j= 0;
    var prev = null;
    while(j < data.length ) {
        
        const eventoverview = [];
        prev= data[j].month;
        while (j < data.length && prev === data[j].month) {
        eventoverview.push(<div className="event">
                                    <div className="information" onClick={ (j) => props.openPopup(j)}>
                                        <div><p className="date">{data[j].daynumber}</p></div>
                                        <div><p className="day">{data[j].weekday}</p></div>
                                        <div><p className="name">{data[j].shortname}</p></div>
                                        <div ><p className="cta" key={j} id={j}>Mehr</p></div>
                                    </div>
                                    <hr />
                                </div>)
            ++j;
        }
        const monthhtml = <div className="month"><h2>{data[j-1].month} {data[j-1].year}</h2>{eventoverview}</div>;
        list.push(monthhtml);
    }
    
    return list.length > 0 ? list : <h1>fehler</h1>
}