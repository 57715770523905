import React, {useState} from 'react';
import emailjs from 'emailjs-com';

import './kontakt.css'

import Call from '../../src/icons/call.png';
import Location from '../../src/icons/location.png';
import Email from '../../src/icons/email.png';

import Facebook from '../../src/icons/facebook.png';
import Instagram from '../../src/icons/instagram.png';

export default function Kontakt() {
    
  const [loadingStatus, setLoadingStatus] = useState(0);

  function sendEmail(e) {
    e.preventDefault();
    setLoadingStatus(1);

    emailjs.sendForm('marius', 'template_hrLqHNBE', e.target, 'user_37zdtXclViV0nMb0jWWzq')
      .then((result) => {
          console.log(result.text);
          setLoadingStatus(2);
      }, (error) => {
          console.log(error.text);
          //erneut versuchen einbauen
      });
  }

  return (
    <div className="Kontakt">
        <div className="container">
            <div className="KontaktInformationWrapper">
                <div className="KontaktInformation">
                    <p>Anliegen, Anfragen und alles weitere klären wir gern persönlich mit dir!</p>
                    <div className="KontaktWege">
                        <div><img src={Call} alt="social-icon" /><a href="tel:+493518020066" target="_blank" rel="noopener noreferrer" >0351 8020066</a></div>
                        <div><img src={Location} alt="social-icon" /><a href="https://goo.gl/maps/HdGb9K8TnCQYjx239" target="_blank"  rel="noopener noreferrer" >Am Brauhaus 3, 01099 Dresden</a></div>
                        <div><img src={Email} alt="social-icon" /><a href="mailto:office@d-g-e.de">office@d-g-e.de</a></div>
                    </div>
      
                    <div className="SocialMedia">
                        <a href="https://www.instagram.com/arteumdresden"  target="_blank" rel="noopener noreferrer">
                            <img src={Instagram} alt="Instagram - Logo"/>
                        </a>
                        <a href="https://www.facebook.com/arteumdresden/" target="_blank" rel="noopener noreferrer">
                            <img src={Facebook} alt="facebook-Logo"/>
                        </a>
                    </div>
                </div>
            </div>
            {false && <div className="FormContainer">
                <form className="contact-form" onSubmit={sendEmail}>
                  <input placeholder="Dein Name" type="text" name="user_name" />
                  <input placeholder="Deine E-Mail Adresse" type="email" name="user_email" />
                  <textarea placeholder="Deine Nachricht" type="text" name="message" />
                  <input type="submit" value="abensden" />
                </form>
                {loadingStatus === 1 ? <div className="loading">lädt!</div> : null }
                {loadingStatus === 2 ? <div className="sent">gesendet!</div> : null }
            
            </div>}
        </div> 
    </div>
    
  );
}