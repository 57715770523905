import React, {useState, useEffect, useCallback} from 'react'
import {useSpring, animated} from 'react-spring'
import { OrderJSONArrayAsc} from '../../OrderJSONArray'

import StartHeroNoEvent from './StartHeroNoEvent.js'
import StartHero from './StartHero.js'
import Dates from './Dates';
import Gallery from './Gallery';
import FAQ from './FAQ';

import Title from '../../GeneralComponents/Title/Title'
import Kontakt from '../../GeneralComponents/Kontakt/Kontakt';
import './Home.css'

export default function MainPage() {
    
    const [events, setEvents] = useState(null);
    const [error, setError] = useState(false)
    const [currentEvent, setCurrentEvent] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    
    useEffect(() => {
        const fbtoken = 'EAALPLIKEx6wBAFpKwlZBHwcMm3N4PgCQuBh2kfhgZC5ZB8Tp00wArE7ZB0OkGBKGlwZAM4swOAT9CT8Y5RQrM1r3a9mEUdZBB09ZAzaZCWUiRaN75K4hF6lepiVnZAhCl1nktTMnqwfLIQ8l24r52SHofV6ZC0X6hdJKjvszY0MSAtZCYQEBdwNPBb1';
        const fetchurl = 'https://graph.facebook.com/124309364248567/events?fields=cover,name,description,start_time&time_filter=upcoming&access_token='+fbtoken;
        
    
        async function fetchData() {
            const response = await fetch(fetchurl);
            const data = await response.json();
            const dataSorted = data.data ? OrderJSONArrayAsc(data.data,"start_time") : null;
            
            const err = (data.error || !data.data || data.data.length === 0) ? true : false;
            setError(err)
            setEvents(dataSorted);
        }
        
        fetchData()
    }, [])
    
    const openPopup = useCallback((index) => {
        document.getElementById('eventPopup').scrollTop = 0
        setCurrentEvent(index.target.id);
        setShowPopup(true);
      }, []);
     
    const PopupAnimation = useSpring({
        transform: showPopup ? 'translate3d(0,0%,0)' : 'translate3d(0,100%,0)'
    })
    
    
    const Popup = events && 
        <animated.div style={PopupAnimation} className="eventPopup" id="eventPopup">
            <p onClick={ () => setShowPopup(false) } className="Closer">×</p>
            <img src={events[currentEvent]?.cover?.source} />
            <h1>{events[currentEvent]?.name}</h1>
            <p className="evenDescription">{events[currentEvent]?.description}</p>
            <p onClick={ () => setShowPopup(false) } className="cta">Schließen</p>
        </animated.div>
   
    return(
        <div className="home">
            { Popup }
            { (events && !error) ?
             <StartHero event={events[0]} openPopup={openPopup} />
             :
             <StartHeroNoEvent />
            }
            

            <Title text="Events" />
            <div style={{ textAlign: 'center' }}>
                <p>Unser nächsten Events findet du hier:</p>
                <br />
                <a style={{ color: 'white'}} href="https://linktr.ee/arteumdresden" target='_blank' >https://linktr.ee/arteumdresden</a>
            </div>
           {events && !error &&  <Dates events={events} openPopup={openPopup} />}
            <Gallery />
            <FAQ />
            <Title text="Kontakt" />
            <Kontakt />
        </div>
    )
}