import React from 'react';
import Title from '../../GeneralComponents/Title/Title'
import './faq.css'
import Icon1 from '../../src/icons/Icon1.png';
import Icon2 from '../../src/icons/Icon2.png';
import Icon3 from '../../src/icons/Icon3.png';
import Icon4 from '../../src/icons/Icon4.png';
import Icon5 from '../../src/icons/Icon5.png';

export default function FAQ() {
    return(
        <div className="FAQ">
            <Title text="FAQ" />
            <div className="container" >
                <div className="FAQWrapper">
                    <div className="Question">
                        <div className="QuestionTitle">
                            <img src={Icon1} alt="icon" />
                            <p>Altersbegrenzung</p>
                        </div>
                        <p>Der Eintritt ist bei uns ausschließlich ab 18 Jahren gestattet. Der Einlass ist nur mit Personalausweis oder Reisepass möglich.</p>
                    </div>
                    
                    <div className="Question">
                        <div className="QuestionTitle">
                            <img src={Icon2} alt="icon" />
                            <p>Fundsachen</p>
                        </div>
                        <p>Haben wir etwas gefunden, bewahren wir es auf. Kontaktiere uns.</p>
                    </div>
        
                    <div className="Question">
                        <div className="QuestionTitle">
                            <img src={Icon3} alt="icon" />
                            <p>Öffnungstage</p>
                        </div>
                        <p>Unsere Veranstaltungen werden im Veranstaltungskalender aufgelistet und auf unserer Facebook-Seite.</p>
                    </div>
        
                    <div className="Question">
                        <div className="QuestionTitle">
                            <img src={Icon4} alt="icon" />
                            <p>Muttizettel</p>
                        </div>
                        <p>Wir akzeptieren keine Begleit- oder Aufsichtspersonen und auch keine Muttizettel.</p>
                    </div>

                    <div className="Question">
                        <div className="QuestionTitle">
                            <img src={Icon5} alt="icon" />
                            <p>Dresscode</p>
                        </div>
                        <p>Der Eintritt wird/kann euch verwehrt werden, wenn ihr... Sporthosen, Jogginganzüge, Trikots, bestimmte Cargohosen, XXL Hoodies, rechte/linke Marken ... tragt.
                            &nbsp;<br /><br />Kurze Hosen sind im Sommer okay, solange sie nicht in die o.g. Kategoeirn fallen! <br /> <br />
                            Einfacher sportlicher oder eleganter Kleidungsstil und alles passt!
                            </p>
                    </div>
        
                </div>
            </div>
        </div>
    )
}