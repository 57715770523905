import React from 'react'
import { NavLink } from 'react-router-dom';

import MobileNav from './MobileNav'
import Logo from '../../src/photos/Logo.png'
import './nav.css';



export default function Header() {
    return (
        window.innerWidth > 800 ? 
        <div className="Header">
        <div className="LogoWrapper">
            <NavLink activeClassName="active" exact to="/" ><img src={Logo} alt="Logo" /></NavLink>
        </div>
        <div className="Navigation">
                <ul>
                <li><NavLink activeClassName="active" exact to="/" >Home</NavLink></li>
                <li><NavLink activeClassName="active" to="/Info" >Info</NavLink></li>
                {/*<li><NavLink activeClassName="active" to="/Galerie" >Galerie</NavLink></li>
    <li><NavLink activeClassName="active" to="/Kontakt" >Kontakt</NavLink></li>*/}
            </ul> 
        </div>
      </div>
      : 
      <MobileNav />
    )
}