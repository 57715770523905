import React, {useState, useEffect} from 'react'
import {useSpring, animated} from 'react-spring'
import { NavLink } from 'react-router-dom';

import './mobileheader.css'
import Logo from '../../src/photos/Logo.png';

export default function MobileHeader() {
    
    const [showNav, setShowNav] = useState(false);
    const [showButton, setShowButton] = useState(false);
     
    let prev = 0;
    
    useEffect(() => {
        prev = window.scrollY;
        window.addEventListener('scroll', e => handleScroll(e));
    }, [])
    
    function handleScroll(event) {
        const window = event.currentTarget;

        var body = document.body,
        html = document.documentElement;    

        var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
                       console.log(height)
        
        if((prev > window.scrollY+10) || (window.scrollY > (height-900)) ) {
            setShowButton(false);
            setShowNav(false);
        } else if (prev < window.scrollY && window.scrollY > 100) {
            setShowButton(true);
        }
        
        prev = window.scrollY;
    }
    
    const buttonAnimation = useSpring({
        transform: showButton ? 'translate3d(-50%,0%,0)' : 'translate3d(-50%,300%,0)'
    })
    
    const navAnimation = useSpring({
        transform: showNav ? 'translate3d(0,0%,0)' : 'translate3d(0,100%,0)'
    })
    
    return(
        <div>
        <div className="MobileTopBar">
            <NavLink activeClassName="active" exact to="/" ><img src={Logo} alt="Logo" /></NavLink>
        </div>
        <animated.div style={buttonAnimation} className="MobileHeader" onClick={ () => setShowNav(!showNav)}>Menü</animated.div>
        <animated.div style={navAnimation} className="MobileNavigation" onClick={ () => setShowNav(!showNav)}>
        <p className="MobileNavigationCloser" onClick={ () => setShowNav(false)}>×</p>   
        <ul>
                <li><NavLink activeClassName="active" exact to="/" >Home</NavLink></li>
                <li><NavLink activeClassName="active" to="/Info" >Info</NavLink></li>
                {/*<li><NavLink activeClassName="active" to="/Galerie" >Galerie</NavLink></li>
                
    <li><NavLink activeClassName="active" to="/Kontakt" >Kontakt</NavLink></li>*/}
            </ul>
        </animated.div>
        </div>
    )
}