import React from 'react'
import Hero from '../../GeneralComponents/HeroTitle/HeroTitle';

import Email from '../../src/icons/email.png';

import './info.css'

export default function Ueber() {
    return(
        <div className="Galerie">
            <Hero text="Info"/>
            <div className="container">
                <h2>Früher die Katakomben einer Brauerei, heute eine moderne Eventlocation - das Arteum.</h2>
                <p className="InfoPageText">
                Mit seinen vier Tonnen (drei davon als Tanzbereich nutzbar) ist das alte Kellergewölbe sehr variabel einsetzbar.
                Sei es für Club-Veranstaltungen, Hochzeiten, Geburtstage, Tagungen oder Weihnachtsfeiern, das Arteum kann von 100-1000 Gästen mit jeder Größe und Umfang umgehen.
                Seine rustikal altertümlichen Wände gepaart mit moderner und stylischer Einrichtung passen dabei in jedes Konzept.
                Gastronomisch und technisch (Sound und Licht) bereits komplett ausgestattet ist das Arteum für sämtliche Anforderungen gewappnet.
                Gern geben wir Auskunft über aktuelle Mietkonditionen und freie Termine. 
                Hierfür bitte an folgende Adresse eine E-Mail senden:<br /><br />
                <div className='KontaktWege'>
                <div><img src={Email} alt="social-icon" /><a href="mailto:office@d-g-e.de">office@d-g-e.de</a></div>
                </div>
                </p>
            </div>
        </div>
    )
}