import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';


import Hero from '../../GeneralComponents/HeroTitle/HeroTitle'
import PictureData from './PictureData';

import './galerie.css'

export default function Galerie() {
    
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    
     const openImageViewer = useCallback((index) => {
        setCurrentImage(parseInt(index.target.id, 10));
        setIsViewerOpen(true);
      }, []);
 
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    
    
    const content = [];
    const images = PictureData.Images;
          
    var placed = 0;
    for (var i = 0; i<PictureData.Data.length; i++) {
        var toplace = PictureData.Data[i].headlineimagecount;
        var temppics = [];
        
        for (var j=placed; j < placed+toplace; ++j) {
          temppics.push(<div key={j} id={j} onClick={ (j) => openImageViewer(j)} className="Image" style={{ backgroundImage : 'url('+ images[j] +')' }} /> )  
        }
        placed = placed+toplace;              
        content.push(<h2 key={j}>{PictureData.Data[i].headline}</h2>)
        content.push(<div key={"div" + j} className="ImagePreview">{temppics}</div>)
    };
    
    return(
        <div className="Galerie">
            {isViewerOpen && (
            <ImageViewer
              src={ images }
              currentIndex={ currentImage  }
              onClose={ closeImageViewer }
            />
          )}
                        
            <Hero text="Galerie" />
            <div className="container">
                {content}
            </div>
        </div>
    )
}

















