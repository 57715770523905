import React from 'react';
import './title.css'

export default function Title(props) {
    return(
        <div className="title">
            <p>{props.text}</p>
            <h1>{props.text}</h1>
        </div>
    )
}