import React from 'react';
import './starthero.css';

import Countdown from 'react-countdown';

export default function App() {
    
    return (
        <div className="Hero">
            <div className="HeroImg">
                 <div className="NextVA">
                 <p className="HeroCountdown">Lifestyle Club & Lounge</p>
                     <h1>Willkommen</h1>
                     <p className="NextVADescription" style={{marginBottom: "2rem"}}>Auf dieser Website findest du allgemeine Infos und den Kontakt zu uns. Sobald es öffentliche Veranstaltungen gibt, werden diese ebenfalls auf dieser Seite angezeigt.</p>
                 </div>
            </div>
        </div>
    )
}