import React from 'react';
import './starthero.css';

import Countdown from 'react-countdown';

export default function App(props) {
    var data = props.event;
    var title = data.name;
    var info = data.description.substring(0, 180)+'...';
    
    //Zeitberechnung für Safari Kompatibel in UNIX
    var date = new Date(data.start_time.substring(0, 10) );
    var time = data.start_time.substring(11, 13) * 3600 * 1000 - ( 2 * 3600 * 1000);
    date = Date.parse(date) + time;
    
    const renderer = ({days,  hours, minutes, seconds}) => {
        return(
            window.innerWidth > 800 ?
            <p className="HeroCountdown">{days} Tage  {hours} Stunden  {minutes} Minuten  {seconds} Sekunden</p>
            :
            <p className="HeroCountdown">{days}d {hours}h {minutes}m {seconds}s</p>
        )
    }
    
    return (
        <div className="Hero">
            <div className="HeroImg">
                 <div className="NextVA">
                     <Countdown date={date} renderer={renderer} />
                     <h1>{title}</h1>
                     <p className="NextVADescription">{info}</p>
                     <p className="primary-cta" key={0} id={0} onClick={ (j) => props.openPopup(j)} >Mehr anzeigen</p>
                 </div>
            </div>
        </div>
    )
}