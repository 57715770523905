import React from 'react';
import './App.css';
import ReactGA from 'react-ga'

import Home from './Pages/Home/Home'
import Galerie from './Pages/Galerie/Galerie'
import Info from './Pages/Info/Info'
import Kontakt from './Pages/Kontakt/Kontakt'
import Impressum from './Pages/Impressum/Impressum'
import Datenschutz from './Pages/Datenschutz/Datenschutz'

import Nav from './GeneralComponents/Navigation/Nav';
import Footer from './GeneralComponents/Footer/Footer';

import ScrollOnChange from './Scroller'

import { BrowserRouter as Router, Switch, Route } from  'react-router-dom'

function App() {

  ReactGA.initialize('UA-217268652-01');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Router>
      <ScrollOnChange>
        <div className="App">
          <div className="BG" />
          <Nav />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/galerie" component={Galerie} />
            <Route path="/info" component={Info} />
            <Route path="/kontakt" component={Kontakt} />
            <Route path="/Impressum" component={Impressum} />
            <Route path="/Datenschutz" component={Datenschutz} />
          </Switch>
          <Footer />
        </div>
      </ScrollOnChange>
    </Router>  
  );
}

export default App;
