import React from 'react'


import Hero from '../../GeneralComponents/HeroTitle/HeroTitle'
import KontaktForm from '../../GeneralComponents/Kontakt/Kontakt'

import './kontakt.css'

export default function Kontakt() {
    return(
        <div className="Galerie">
            <Hero text="Kontakt"/>
            <div className="container">
                <h2 className="KontaktTitel">Wir sind n' toller Club</h2>
                <p className="KontaktText">Offene Fragen? Bookingwünsche? Kontaktiere uns bequem hier :).</p>
            </div>    
            <KontaktForm />
        </div>
    )
}